<template>
  <div class=" ">
    <Navbar />
    <Dialog :visible="showTalepDialog" :dismissableMask="true" :breakpoints="{ '960px': '75vw' }" :closable="false"
      :showHeader="false" :style="{ width: '50vw' }" position="center">
      <div class="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
        <i class="pi pi-check-circle" :style="{ fontSize: '5rem', color: 'var(--green-500)' }"></i>
        <h5>Talebiniz Alınmıştır</h5>
        <p :style="{ lineHeight: 1.5, textIndent: '1rem' }">
          <b style="font-size: 2.5rem">{{ TalepNo }}</b><br />
          Takip numarasından talebinizi takip edebilirsiniz
        </p>
      </div>
      <template #footer>
        <div class="p-d-flex p-jc-center">
          <Button label="Tamam" @click="toggleTalepDialog" class="p-button-text" />
        </div>
      </template>
    </Dialog>

    <Dialog :visible="showTalepKontrolDialog" :dismissableMask="true" :breakpoints="{ '960px': '75vw' }"
      :closable="false" :style="{ width: '50vw' }" position="center">
      <template #header>
        <div class="grid">
          <div class="col-6">
            <div class="font-bold">
              <strong>Talebinizi gözden geçirin</strong>
            </div>
          </div>
          <div class="col-6">
            <div style="color: red; font-size: small">
              Talep oluşturulduktan sonra değişiklik yapamazsınız. Değişiklik
              için bizimle takip numaranız ile irtibata geçin.
            </div>
          </div>
        </div>
      </template>

      <div class="p-grid">
        <div class="p-col">
          <div class="p-col-6">
            <b>Tur :</b> {{ this.UrunAdText }} <br />
            <b>Marka :</b> {{ this.ModelAdText }} <br />
            <b>Model :</b> {{ this.MarkaAdText }} <br />
          </div>
          <div class="p-col-6">
            <b>Arıza Açıklaması :</b> <br />
            {{ this.txtAriza }}
          </div>
        </div>
        <div class="p-col">
          <b>Ad Soyad :</b> {{ this.txtAdSoyad }} <br />
          <b>Telefon No :</b> {{ this.txtTelefonNo }} <br />
          <b>Mail :</b> {{ this.txtMailAdres }} <br />
          <b>Şehir / İlçe :</b> {{ this.SehirText }} / {{ this.IlceText }}
          <br />
          <b>Adres :</b> {{ this.txtAdress }} <br />
        </div>
      </div>

      <template #footer>
        <div class="p-d-flex p-jc-center">
          <Button label="Onayla" @click="this.handleClick(!v$.$invalid)" class="p-button-text" />
          <Button label="Reddet" v-on:click="this.showTalepKontrolDialog = false" class="p-button-text" />
        </div>
      </template>
    </Dialog>

    <ScrollPanel style="width: 100%; margin-top: 2vh" :style="{ 'height': windowHeight + 'px' }">
      <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center p-mt-6">
        <Panel id="panel" header=" Destek Talebi " class="">
          <div class="p-grid p-formgrid p-d-flex p-jc-center">
            <div class="p-fluid p-col-md-6 p-col-xs-6">
              <div class="p-field p-col-12">
                <label for="ProductType" :class="{
      'p-error': v$.SelectedProductType.$invalid && submitted,
    }">Cihaz türü</label>
                <Dropdown filter id="ProductType" v-model="SelectedProductType" :options="ProductType" :class="{
      'p-invalid': v$.SelectedProductType.$invalid && submitted,
    }" optionLabel="UrunAdi" optionValue="UrunKey" placeholder="Seçiniz..." @change="ProductTypeChange" />
                <small v-if="(v$.SelectedProductType.$invalid && submitted) ||
      v$.SelectedProductType.$pending.$response
      " class="p-error">{{
      v$.SelectedProductType.required.$message.replace(
        "Value",
        "Name"
      )
    }}</small>
              </div>
              <div class="p-field p-col-12">
                <label for="ProductTradeMark" :class="{
        'p-error': v$.SelectedProductTradeMark.$invalid && submitted,
      }">Cihaz Markası</label>
                <Dropdown filter id="ProductTradeMark" :disabled="ProductTradeMarkDisabled" v-model="SelectedProductTradeMark"
                  :options="ProductTradeMark" :class="{
      'p-invalid':
        v$.SelectedProductTradeMark.$invalid && submitted,
    }" @change="ProductTradeMarkChange" optionLabel="MarkaAdi" optionValue="MarkaKey" placeholder="Seçiniz..." />
                <small v-if="(v$.SelectedProductTradeMark.$invalid && submitted) ||
      v$.SelectedProductTradeMark.$pending.$response
      " class="p-error">{{
      v$.SelectedProductTradeMark.required.$message.replace(
        "Value",
        "Name"
      )
    }}</small>
              </div>
              <div class="p-field p-col-12">
                <label for="ProductModel" :class="{
        'p-error': v$.SelectedProductModel.$invalid && submitted,
      }">Cihaz Modeli</label>
                <Dropdown filter id="ProductModel" :disabled="ProductModelDisabled" v-model="SelectedProductModel"
                  :options="ProductModel" :class="{
      'p-invalid': v$.SelectedProductModel.$invalid && submitted,
    }" optionLabel="ModelAdi" optionValue="ModelKey" placeholder="Seçiniz..." />
                <small v-if="(v$.SelectedProductModel.$invalid && submitted) ||
      v$.SelectedProductModel.$pending.$response
      " class="p-error">{{
      v$.SelectedProductModel.required.$message.replace(
        "Value",
        "Name"
      )
    }}</small>
              </div>
              <div class="p-field p-col-12">
                <label for="txtAriza" :class="{ 'p-error': v$.txtAriza.$invalid && submitted }">Arıza Açıklaması</label>
                <Textarea id="txtAriza" rows="3" v-model="v$.txtAriza.$model"
                  :class="{ 'p-invalid': v$.txtAriza.$invalid && submitted }" />
                <small v-if="(v$.txtAriza.$invalid && submitted) ||
      v$.txtAriza.$pending.$response
      " class="p-error">{{
      v$.txtAriza.required.$message.replace("Value", "Name")
    }}</small>
              </div>
            </div>
            <div class="p-fluid p-col-md-6 p-col-xs-6">
              <div class="p-field p-col-12">
                <label for="txtAdSoyad" :class="{ 'p-error': v$.txtAdSoyad.$invalid && submitted, }">Ad Soyad</label>
                <InputText id="txtAdSoyad" type="text" v-model="v$.txtAdSoyad.$model"
                  :class="{ 'p-invalid': v$.txtAdSoyad.$invalid && submitted }" />
                <small v-if="(v$.txtAdSoyad.$invalid && submitted) || v$.txtAdSoyad.$pending.$response"
                  class="p-error">{{
      v$.txtAdSoyad.required.$message.replace("Value", "Name") }}</small>
              </div>
              <div class="p-field p-col-12">
                <label for="txtTelefonNo" :class="{
      'p-error': v$.txtTelefonNo.$invalid && submitted,
    }">Telefon Numarası</label>
                <InputMask id="txtTelefonNo" type="text" mask="(999) 999 99 99" v-model="v$.txtTelefonNo.$model"
                  :class="{ 'p-invalid': v$.txtTelefonNo.$invalid && submitted }" />
                <small v-if="(v$.txtTelefonNo.$invalid && submitted) ||
      v$.txtTelefonNo.$pending.$response
      " class="p-error">{{
      v$.txtTelefonNo.required.$message.replace("Value", "Name")
    }}</small>
              </div>
              <div class="p-field p-col-12">
                <label for="txtMailAdres" :class="{
        'p-error': v$.txtMailAdres.$invalid && submitted,
      }">Mail Adres</label>
                <InputText id="txtMailAdres" type="text" v-model="v$.txtMailAdres.$model"
                  :class="{ 'p-invalid': v$.txtMailAdres.$invalid && submitted }" />
                <small v-if="(v$.txtMailAdres.$invalid && submitted) ||
      v$.txtMailAdres.$pending.$response
      " class="p-error">{{
      v$.txtMailAdres.required.$message.replace("Value", "Name")
    }}</small>
              </div>
              <div class="p-field p-col-12">
                <label for="CustomerCity" :class="{
        'p-error': v$.SelectedCustomerCity.$invalid && submitted,
      }">Şehir</label>
                <Dropdown filter id="CustomerCity" v-model="SelectedCustomerCity" :options="City" :class="{
      'p-invalid': v$.SelectedCustomerCity.$invalid && submitted,
    }" optionLabel="SehirAdi" optionValue="SehirKod" placeholder="Seçiniz..." @change="CityChange" />
                <small v-if="(v$.SelectedCustomerCity.$invalid && submitted) ||
      v$.SelectedCustomerCity.$pending.$response
      " class="p-error">{{
      v$.SelectedCustomerCity.required.$message.replace(
        "Value",
        "Name"
      )
    }}</small>
              </div>
              <div class="p-field p-col-12">
                <label for="CustomerDistrict" :class="{
        'p-error': v$.SelectedCustomerDistrict.$invalid && submitted,
      }">İlçe</label>
                <Dropdown filter id="CustomerDistrict" :disabled="CustomerDistrictDisabled" v-model="SelectedCustomerDistrict"
                  :options="District" :class="{
      'p-invalid':
        v$.SelectedCustomerDistrict.$invalid && submitted,
    }" optionLabel="IlceAdi" optionValue="IlceKod" placeholder="Seçiniz..." />
                <small v-if="(v$.SelectedCustomerDistrict.$invalid && submitted) ||
      v$.SelectedCustomerDistrict.$pending.$response
      " class="p-error">{{
      v$.SelectedCustomerDistrict.required.$message.replace(
        "Value",
        "Name"
      )
    }}</small>
              </div>
              <div class="p-field p-col-12">
                <label for="txtAdress" :class="{ 'p-error': v$.txtAdress.$invalid && submitted }">Adress</label>
                <Textarea id="txtAdress" rows="2" v-model="v$.txtAdress.$model"
                  :class="{ 'p-invalid': v$.txtAdress.$invalid && submitted }" />
                <small v-if="(v$.txtAdress.$invalid && submitted) ||
      v$.txtAdress.$pending.$response
      " class="p-error">{{
      v$.txtAdress.required.$message.replace("Value", "Name")
    }}</small>
              </div>
            </div>
          </div>
          <!-- </template>
        <template #footer> -->
          <div class="p-grid p-formgrid p-d-flex p-jc-center">
            <div class="p-col-12 ">
              <div class="field-checkbox">
                <Checkbox disabled="true" id="chckGizlilik" binary name="Gizlilik" v-model="chckGizlilik" />
                <label for="chckGizlilik">&nbsp;<a style="cursor:pointer; " href="#"
                    @click.prevent="GizlilikPolitikasi_Modal()">Gizlilik politikasını</a> kabul ediyorum.</label>
              </div>
              <div class="field-checkbox">
                <Checkbox disabled="true" id="chckSartlarveKosullar" binary name="SartlarveKosullar"
                  v-model="chckSartlarveKosullar" />
                <label for="chckSartlarveKosullar">&nbsp;<a href="#" @click.prevent="SartlarVeKosullar_Modal()">Şartlar
                    ve
                    Koşulları</a> kabul ediyorum.</label>
              </div>
            </div>
            <div class="p-col-12 p-d-flex p-jc-center p-mt-3">
              <Button icon="pi pi-check" label="Talep Oluştur" @click="toggleTalepKontrolDialog"
                :disable="TalepOlusturButtonDisable" />
            </div>
          </div>
        </Panel>

        <div class="p-grid">
          <div class="p-col-12  p-d-flex p-jc-center">
            <h2>Bir sorununuz mu var? Bize ulaşın..</h2>
          </div>
          <div class="p-col-12  p-d-flex p-jc-center">
            <router-link to="/WhatsappTalepOlustur"><a aria-label="Chat on WhatsApp"><img alt="Chat on WhatsApp"
                  src="../../../assets/whatsapp/WhatsAppButtonGreenSmall.svg" /> </a></router-link>
          </div>
        </div>
      </div>
    </ScrollPanel>

    <Dialog v-model:visible="GizlilikPolitikasi_Goster" maximizable modal header="Sözleşmeler" position="top"
      :draggable="false" :style="{ width: '70rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p class="m-0">
        <GizlilikPolitikasi v-on:get-onay-data="GizlilikPolitikasiOnay" />
      </p>
    </Dialog>

    <Dialog v-model:visible="SartlarVeKosullar_Goster" maximizable modal header="Sözleşmeler" position="top"
      :draggable="false" :style="{ width: '70rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p class="m-0">
        <SartlarVeKosullar v-on:get-onay-data="SartlarVeKosullarOnay" />
      </p>
    </Dialog>

  </div>
</template>

<script>
import { required, helpers, email } from "@vuelidate/validators";
import GlobalServis from "../../../services/GlobalServis";
import InfoService from "../../../services/InfoService";
import { useVuelidate } from "@vuelidate/core";
import Navbar from "../navbar.vue";
import GizlilikPolitikasi from "../Politikalar ve Sozlesmeler/GizlilikPolitikasi/template_GizlilikPolitikasi.vue"
import SartlarVeKosullar from "../Politikalar ve Sozlesmeler/SartlarVeKosullar/template_SartlarVeKosullar.vue"

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      GizlilikPolitikasi_Goster: false,
      SartlarVeKosullar_Goster: false,
      //
      allData: null,
      // Talep
      SelectedProductType: null,
      SelectedProductTradeMark: null,
      SelectedProductModel: null,
      SelectedCustomerCity: null,
      SelectedCustomerDistrict: null,
      txtAriza: "",
      txtAdSoyad: "",
      txtTelefonNo: "",
      txtMailAdres: "",
      txtAdress: "",
      TalepNo: "",
      Barkod: "",
      //
      submitted: false,
      showTalepDialog: false,
      showTalepKontrolDialog: false,
      ProductType: [],
      ProductTradeMark: [],
      ProductTradeMarkDisabled: true,
      ProductModel: [],
      City: [],
      District: [],
      ProductModelDisabled: true,
      CustomerDistrictDisabled: true,
      KullaniciBilgi: {
        IPAdres: null,
        BolgeAd: null,
        BolgeKod: null,
      },
      //
      UrunAdText: "",
      ModelAdText: "",
      MarkaAdText: "",
      SehirText: "",
      IlceText: "",
      //
      chckGizlilik: false,
      chckSartlarveKosullar: false,

      windowHeight: window.innerHeight,
      TalepOlusturButtonDisable: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    }),
      GlobalServis.GlobalServis("GET", "GetTalepBilgi", "").then((res) => {
        if (res.status == 200) {
          this.allData = res.data;
          this.ProductType = res.data.UrunTur;
          this.City = res.data.Sehir;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    handleClick(isFormValid) {
      this.submitted = true;
      this.TalepOlusturButtonDisable = true;
      if (isFormValid) {
        InfoService.InfoService()
          .then((res) => {
            if (res.status === 200) {
              this.KullaniciBilgi.IPAdres = res.data.ipAddress;
              this.KullaniciBilgi.BolgeKod = res.data.continentCode;
              this.KullaniciBilgi.BolgeAd = res.data.continentName;
            }
          })
          .then(() => {
            let TalepListesi = {
              CihazTuru: this.SelectedProductType,
              CihazMarkasi: this.SelectedProductTradeMark,
              CihazModeli: this.SelectedProductModel,
              ArizaAciklamasi: this.txtAriza,
              MusteriAdi: this.txtAdSoyad,
              MusteriTelefonNo: this.txtTelefonNo,
              MusteriMailAdres: this.txtMailAdres,
              MusteriAdres: this.txtAdress,
              MusteriSehir: this.SelectedCustomerCity,
              MusteriIlce: this.SelectedCustomerDistrict,
              IPAdres: this.KullaniciBilgi.IPAdres,
              BolgeKod: this.KullaniciBilgi.BolgeKod,
              BolgeAd: this.KullaniciBilgi.BolgeAd,
            };
            GlobalServis.GlobalServis(
              "POST",
              "SetTalepOlustur",
              TalepListesi
            ).then((res) => {
              if (res.data["Durum"] === "Basarili") {
                this.toggleTalepDialog(res.data["Barkod"]);
                this.Barkod = res.data["Barkod"];
                this.TalepOlusturButtonDisable = false;
              } else if (res.data["Durum"] === "Basarisiz") {
                this.TalepOlusturButtonDisable = false;
                this.$toast.add({
                  severity: "warn",
                  summary: "Birşeyler yanlış oldu",
                  detail: res.data["Aciklama"],
                  life: 3000,
                });
              }
            });
          });

        // this.resetForm();
        return;
      } else {
        this.TalepOlusturButtonDisable = false;
        this.$toast.add({
          severity: "warn",
          summary: "Gerekli Alan Uyarısı",
          detail: "Lütfen tüm alanları doldurunuz",
          life: 3000,
        });
      }
    },
    TalepOnayla() {
      this.toggleTalepDialog(this.Barkod);
    },
    toggleTalepKontrolDialog() {
      if (!this.chckGizlilik) {
        this.$toast.add({
          severity: "error",
          summary: "Uyarı",
          detail: "Gizlilik Sözleşmesini kabul etmelisiniz.",
          life: 3000,
        });
        return;
      }
      if (!this.chckSartlarveKosullar) {
        this.$toast.add({
          severity: "error",
          summary: "Uyarı",
          detail: "Şartlar ve Koşullar Sözleşmesini kabul etmelisiniz.",
          life: 3000,
        });
        return;
      }

      this.submitted = true;
      if (!this.v$.$invalid) {
        this.ProductType.forEach((element) => {
          if (element.UrunKey == this.SelectedProductType) {
            this.UrunAdText = element.UrunAdi;
          }
        });
        this.ProductTradeMark.forEach((element) => {
          if (element.MarkaKey == this.SelectedProductTradeMark) {
            this.MarkaAdText = element.MarkaAdi;
          }
        });
        this.ProductModel.forEach((element) => {
          if (element.ModelKey == this.SelectedProductModel) {
            this.ModelAdText = element.ModelAdi;
          }
        });
        this.City.forEach((element) => {
          if (element.SehirKod == this.SelectedCustomerCity) {
            this.SehirText = element.SehirAdi;
          }
        });
        this.District.forEach((element) => {
          if (element.IlceKod == this.SelectedCustomerDistrict) {
            this.IlceText = element.IlceAdi;
          }
        });

        this.showTalepKontrolDialog = !this.showTalepKontrolDialog;
      }
    },
    toggleTalepDialog(TalepNo) {
      this.showTalepDialog = !this.showTalepDialog;
      if (!this.showTalepDialog) {
        this.showTalepKontrolDialog = false;
        this.resetForm();
      } else {
        this.TalepNo = TalepNo;
      }
    },
    ProductTypeChange(e) {
      this.ProductTradeMark = this.allData.UrunMarka.filter(function (i) {
        return i.pUrunTur == e.value;
      });
      this.ProductTradeMarkDisabled = false;
    },
    ProductTradeMarkChange(e) {
      this.ProductModel = this.allData.UrunModel.filter(function (i) {
        return i.pMarka == e.value;
      });
      this.ProductModelDisabled = false;
    },
    CityChange(e) {
      this.District = this.allData.Ilce.filter(function (i) {
        if (e.value == 0) {
          return i.pSehir == e.value.substring(1);
        } else {
          return i.pSehir == e.value;
        }
      });
      this.CustomerDistrictDisabled = false;
    },
    resetForm() {
      // this.v$.$reset;
      this.SelectedProductType = null;
      this.SelectedProductTradeMark = null;
      this.SelectedProductModel = null;
      this.SelectedCustomerCity = null;
      this.SelectedCustomerDistrict = null;
      this.txtAriza = "";
      this.txtAdSoyad = "";
      this.txtTelefonNo = "";
      this.txtMailAdres = "";
      this.txtAdress = "";
      this.submitted = false;
      this.ProductTradeMarkDisabled = true;
      this.ProductModelDisabled = true;
      this.TalepNo = "";
      this.Barkod = "";
      this.chckGizlilik = false;
      this.chckSartlarveKosullar = false;
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
    GizlilikPolitikasi_Modal() {
      this.GizlilikPolitikasi_Goster = !this.GizlilikPolitikasi_Goster;
    },
    GizlilikPolitikasiOnay(value) {
      this.chckGizlilik = true;
      this.GizlilikPolitikasi_Modal();
    },
    SartlarVeKosullar_Modal() {
      this.SartlarVeKosullar_Goster = !this.SartlarVeKosullar_Goster;
    },
    SartlarVeKosullarOnay(value) {
      this.chckSartlarveKosullar = true;
      this.SartlarVeKosullar_Modal();
    }
  },
  validations() {
    return {
      SelectedProductType: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SelectedProductTradeMark: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SelectedProductModel: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SelectedCustomerCity: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      SelectedCustomerDistrict: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      txtAriza: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      txtAdSoyad: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      txtTelefonNo: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      txtMailAdres: {
        required: helpers.withMessage("Geçerli bir adres girin", required),
        email,
      },
      txtAdress: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
  components: {
    Navbar,
    GizlilikPolitikasi,
    SartlarVeKosullar
  },

};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 489px) {
  .p-formgroup-inline {
    .p-field {
      margin-bottom: 1em !important;
    }
  }
}

textarea {
  resize: none;
}

.centered {
  position: fixed;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #cdd4cd;
}
</style>
