<template>

    <div class="p-grid">
        <div class="p-col-12 p-d-flex p-jc-center">
            <img alt="user header" src="../../../../assets/logo/logo1.png" style="width: 40%;" />
        </div>
        <div class="p-col-12 p-d-flex p-jc-center">
            <h1>ŞARTLAR VE KOŞULLAR</h1>
        </div>
        <div class="p-col-12 p-d-flex p-jc-center">
            <div style="font-size: 10px; font-weight: 300; font-style: italic;">Güncelleme Tarihi : 20 Mayıs 2024 </div>
        </div>
        <div class="p-col-12">
            <h2><b>Genel Şartlar</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik'e erişerek ve sipariş vererek, aşağıda belirtilen Şartlar ve Koşullarda yer alan hizmet şartlarını kabul ettiğinizi ve bunlara bağlı olduğunuzu onaylamış olursunuz. Bu şartlar, web sitesinin tamamı ve sizinle Gelsin Teknik arasındaki her türlü e-posta veya diğer iletişim türleri için geçerlidir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik ekibi, bu sitedeki materyallerin kullanımından veya kullanılamamasından kaynaklanan veri veya kar kaybı dahil ancak bunlarla sınırlı olmamak üzere, doğrudan, dolaylı, özel, arızi veya sonuç olarak ortaya çıkan zararlardan hiçbir koşul altında, Gelsin Teknik ekibi veya yetkili bir temsilcisi bu tür zararların olasılığı hakkında bilgilendirilmiş olsa bile sorumlu tutulamaz. Bu sitedeki materyalleri kullanmanız, ekipman veya verilerin servis, onarım veya düzeltilmesi ihtiyacını doğurursa, bunların tüm masraflarını üstlenirsiniz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, kaynaklarımızın kullanımı sırasında ortaya çıkabilecek sonuçlardan sorumlu olmayacaktır. Her an fiyatları değiştirme ve kaynak kullanım politikasını revize etme hakkımız saklıdır. 
            </p>
        </div>

        <div class="p-col-12">
            <h2><b>Lisans</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, web sitesini kesinlikle bu Sözleşmenin şartlarına uygun olarak indirmeniz, kurmanız ve kullanmanız için size geri alınabilir, münhasır olmayan, devredilemez, sınırlı bir lisans verir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Bu Şartlar ve Koşullar, siz ve Gelsin Teknik (bu Şartlar ve Koşullar'da "Gelsin Teknik", "bize", "biz" veya "bizim" olarak anılacaktır), Gelsin Teknik web sitesinin ve Gelsin Teknik web sitesinden erişilebilen hizmetlerin sağlayıcısı (bu Şartlar ve Koşullar'da topluca "Gelsin Teknik Hizmeti" olarak anılacaktır) arasında bir sözleşmedir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Bu Şartlar ve Koşullara bağlı kalmayı kabul etmektesiniz. Bu Şartlar ve Koşulları kabul etmiyorsanız, lütfen Gelsin Teknik Servisini kullanmayın. Bu Şartlar ve Koşullarda, "siz" hem bir birey olarak sizi hem de temsil ettiğiniz kuruluşu ifade eder. Bu Şartlar ve Koşullardan herhangi birini ihlal ederseniz, bildirimde bulunmaksızın hesabınızı iptal etme veya hesabınıza erişimi engelleme hakkımızı saklı tutarız.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Tanımlar ve anahtar terimler</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bu Şartlar ve Koşullar'da her şeyi olabildiğince açık bir şekilde açıklamaya yardımcı olmak için, bu şartlardan herhangi birine atıfta bulunulduğunda, kesinlikle şu şekilde tanımlanır:
            </p>
            <ul>
                <li class="p-m-2">Çerez: Bir web sitesi tarafından oluşturulan ve web tarayıcınız tarafından kaydedilen az miktarda veri. Tarayıcınızı tanımlamak, analiz sağlamak, dil tercihiniz veya oturum açma bilgileriniz gibi sizinle ilgili bilgileri hatırlamak için kullanılır.</li>
                <li class="p-m-2">Şirket: Bu şartlarda "Şirket", "biz", "biz" veya "bizim" ifadeleri kullanıldığında, bu Şartlar ve Koşullar kapsamındaki bilgilerinizden sorumlu olan Gelsin Teknik'i (Semerciler Mahallesi Saraçlar Sokak Yener İÅŸ Merkezi no:306-307 ADAPAZARI/SAKARYA) ifade eder.</li>
                <li class="p-m-2">Ülke: Gelsin Teknik'in veya Gelsin Teknik'in sahiplerinin/kurucularının bulunduğu yer, bu durumda Türkiye</li>
                <li class="p-m-2">Cihaz: Telefon, tablet, bilgisayar veya Gelsin Teknik'i ziyaret etmek ve hizmetleri kullanmak için kullanılabilecek herhangi bir internet bağlantılı cihaz.</li>
                <li class="p-m-2">Hizmet: Gelsin Teknik tarafından ilgili şartlarda (varsa) ve bu platformda açıklandığı şekilde sağlanan hizmeti ifade eder.</li>
                <li class="p-m-2">Üçüncü taraf hizmeti: Â, reklamcıları, yarışma sponsorlarını, promosyon ve pazarlama ortaklarını ve içeriğimizi sağlayan veya ürün veya hizmetlerinin ilginizi çekebileceğini düşündüğümüz diğer kişileri ifade eder.</li>
                <li class="p-m-2">Web Sitesi: Gelsin Teknik'™in sitesi, bu URL üzerinden erişilebilir: www.gelsinteknik.com</li>
                <li class="p-m-2">Siz: Hizmetleri kullanmak için Gelsin Teknik'e kayıtlı olan kişi veya kuruluş.</li>
            </ul>
        </div>
        <div class="p-col-12">
            <h2><b>Kısıtlamalar</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Aşağıdakileri yapmamayı kabul edersiniz ve başkalarının yapmasına izin vermeyeceksiniz:
            </p>
            <ul>
                <li class="p-m-2">Web sitesini lisanslamak, satmak, kiralamak, kiraya vermek, devretmek, dağıtmak, iletmek, barındırmak, dış kaynak kullanmak, ifşa etmek veya başka bir şekilde ticari olarak kullanmak veya platformu herhangi bir üçüncü tarafın kullanımına sunmak.</li>
                <li class="p-m-2">Web sitesinin herhangi bir bölümünü değiştirmek, türev çalışmalar yapmak, parçalarına ayırmak, şifresini çözmek, tersine derlemek veya tersine mühendislik yapmak.</li>
                <li class="p-m-2">Gelsin Teknik'in veya bağlı kuruluşlarının, ortaklarının, tedarikçilerinin veya web sitesinin lisans verenlerinin herhangi bir mülkiyet bildirimini (herhangi bir telif hakkı veya ticari marka bildirimi dahil) kaldırmak, değiştirmek veya gizlemek.</li>
            </ul>
        </div>
        <div class="p-col-12">
            <h2><b>Ödeme</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Tek seferlik ödeme planlarımızdan herhangi biri için ödeme yaparsanız, her bir ücretin veya ücretin vadesi geldiği ve ödeneceği tarihte yürürlükte olan ücretlere, masraflara ve faturalandırma koşullarına uygun olarak Hizmet için hesabınıza tüm ücretleri veya masrafları ödemeyi kabul edersiniz. Ödeme Sağlayıcı sözleşmeniz, belirtilen kredi kartı hesabını kullanımınızı yönetir ve Ödeme Sağlayıcınızla ilgili haklarınızı ve yükümlülüklerinizi belirlemek için bu Koşullara değil, bu sözleşmeye başvurmanız gerekir. Gelsin Teknik'e kredi kartı numaranızı ve ilgili ödeme bilgilerinizi vererek, Gelsin Teknik'in bilgileri derhal doğrulamaya yetkili olduğunu ve daha sonra Gelsin Teknik'e ödenmesi gereken ve ödenmesi gereken tüm ücret ve masraflar için hesabınızı faturalandırmaya yetkili olduğunu ve ek bir bildirim veya onay gerekmediğini kabul etmiş olursunuz. Fatura adresinizde veya ödeme için kullanılan kredi kartınızda herhangi bir değişiklik olması durumunda Gelsin Teknik'i derhal bilgilendirmeyi kabul etmektesiniz. Gelsin Teknik, Sitemizde yayınlandıktan hemen sonra veya kuruluşunuzun ™yöneticisine/yöneticilerine e-posta göndererek fiyatlarını ve faturalandırma yöntemlerini herhangi bir zamanda değiştirme hakkını saklı tutar.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gecikmiş ihtilafsız tutarların tahsilatında ortaya çıkan herhangi bir avukatlık ücreti, mahkeme masrafı veya diğer masraflar sizin sorumluluğunuzda olacak ve sizin tarafınızdan ödenecektir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik siparişinizi doğrulayıcı bir e-posta, SMS/MMS mesajı veya diğer uygun iletişim araçları ile kabul edene kadar Hizmet için sizinle Gelsin Teknik arasında herhangi bir sözleşme olmayacaktır.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Hizmeti kullanırken maruz kalabileceğiniz üçüncü taraf ücretlerinden siz sorumlusunuz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>İade ve Geri Ödeme Politikası</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik'ten alışveriş yaptığınız için teşekkür ederiz. İnşa ettiğimiz şeyleri satın almayı sevdiğiniz için teşekkür ederiz. Ayrıca ürünlerimizi keşfederken, değerlendirirken ve satın alırken ödüllendirici bir deneyim ™yaşadığınızdan emin olmak istiyoruz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Her alışveriş deneyiminde olduğu gibi, Gelsin Teknik'te de işlemler için geçerli olan şartlar ve koşullar vardır. ™Avukatlarımızın izin verdiği kadar kısa olacağız. Unutulmaması gereken en önemli şey, Gelsin Teknik'te sipariş vererek veya satın alarak Gelsin Teknik'™in Gizlilik Politikası ile birlikte şartları kabul etmiş olmanızdır.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Herhangi bir nedenle, sağladığımız herhangi bir mal veya hizmetten tamamen memnun kalmazsanız, bizimle iletişime geçmekten çekinmeyin ve ürünümüzle ilgili yaşadığınız sorunları tartışacağız.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Önerileriniz</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Web sitesi ile ilgili olarak Gelsin Teknik'e sağladığınız her türlü geri bildirim, yorum, fikir, iyileştirme veya öneri (topluca "Öneriler") yalnızca ve münhasıran Gelsin Teknik'in mülkiyetinde kalacaktır.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, Önerileri herhangi bir amaçla ve herhangi bir şekilde size herhangi bir kredi veya tazminat ödemeden kullanmak, kopyalamak, değiştirmek, yayınlamak veya yeniden dağıtmakta serbest olacaktır.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Sizin Onayınız</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Sitemizi ziyaret ettiğinizde nelerin ayarlandığı ve nasıl kullanıldığı konusunda size tam şeffaflık sağlamak için Şartlar ve Koşullarımızı güncelledik. Web sitemizi kullanarak, bir hesap açarak veya bir satın alma işlemi yaparak, Şartlar ve Koşullarımızı kabul etmiş olursunuz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Diğer Web Sitelerine Bağlantılar</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bu Şartlar ve Koşullar yalnızca Hizmetler için geçerlidir. Hizmetler, Gelsin Teknik tarafından işletilmeyen veya kontrol edilmeyen diğer web sitelerine bağlantılar içerebilir. Bu tür web sitelerinde ifade edilen içerik, doğruluk veya görüşlerden sorumlu değiliz ve bu tür web siteleri tarafımızca araştırılmaz, izlenmez veya doğruluk veya eksiksizlik açısından kontrol edilmez. Lütfen, Hizmetlerden başka bir web sitesine gitmek için bir bağlantı kullandığınızda, Şartlar ve Koşullarımızın artık yürürlükte olmadığını unutmayın. Platformumuzda bağlantısı olanlar da dahil olmak üzere başka herhangi bir web sitesinde gezinmeniz ve etkileşiminiz, o web sitesinin ™kendi kurallarına ve politikalarına tabidir. Bu tür üçüncü taraflar, hakkınızda bilgi toplamak için kendi çerezlerini veya diğer yöntemlerini kullanabilir.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Çerezler</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, web sitemizin ziyaret ettiğiniz alanlarını tanımlamak için "Çerezler" kullanır. Çerez, web tarayıcınız tarafından bilgisayarınızda veya mobil cihazınızda depolanan küçük bir veri parçasıdır. Çerezleri, web sitemizin performansını ve işlevselliğini geliştirmek için kullanıyoruz, ancak kullanımları için gerekli değildir. Ancak, bu çerezler olmadan, videolar gibi belirli işlevler kullanılamayabilir veya daha önce oturum açtığınızı hatırlayamayacağımız için web sitesini her ziyaret ettiğinizde oturum açma bilgilerinizi girmeniz gerekebilir. Çoğu web tarayıcısı, Çerezlerin kullanımını devre dışı bırakacak şekilde ayarlanabilir. Ancak, Çerezleri devre dışı bırakırsanız, web sitemizdeki işlevlere doğru veya hiç erişemeyebilirsiniz. Kişisel Olarak Tanımlanabilir Bilgileri asla Çerezlere yerleştirmeyiz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Şartlar ve Koşullarımızdaki Değişiklikler</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik'™in, size önceden bildirimde bulunmaksızın, tamamen kendi takdirine bağlı olarak, size veya genel olarak kullanıcılara Hizmeti (veya Hizmet içindeki herhangi bir özelliği) sağlamayı (kalıcı veya geçici olarak) durdurabileceğini kabul ve beyan edersiniz. Hizmeti kullanmayı istediğiniz zaman bırakabilirsiniz. Hizmet'i kullanmayı bıraktığınızda Gelsin Teknik'i özel olarak bilgilendirmeniz gerekmez. Gelsin Teknik'in hesabınıza erişimi devre dışı bırakması durumunda, Hizmete, hesap bilgilerinize veya hesabınızda bulunan herhangi bir dosya veya diğer materyallere erişiminizin engellenebileceğini kabul ve beyan edersiniz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Şartlar ve Koşullarımızı değiştirmeye karar verirsek, bu değişiklikleri bu sayfada yayınlayacağız ve / veya aşağıdaki Şartlar ve Koşullar değişiklik tarihini güncelleyeceğiz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Web Sitemizdeki Değişiklikler</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, web sitesini veya bağlandığı herhangi bir hizmeti, bildirimde bulunarak veya bulunmadan ve size karşı herhangi bir yükümlülük altına girmeksizin değiştirme, askıya alma veya geçici veya kalıcı olarak durdurma hakkını saklı tutar.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Web Sitemizdeki Güncellemeler</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik zaman zaman web sitesinin özelliklerinde/işlevlerinde yamalar, hata düzeltmeleri, güncellemeler, yükseltmeler ve diğer değişiklikleri ("Güncellemeler") içerebilecek geliştirmeler veya iyileştirmeler sağlayabilir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Güncellemeler, web sitesinin belirli özelliklerini ve/veya işlevlerini değiştirebilir veya silebilir. Gelsin Teknik'in (i) herhangi bir Güncelleme sağlama veya (ii) web sitesinin belirli özelliklerini ve/veya işlevlerini size sağlamaya veya etkinleştirmeye devam etme yükümlülüğü olmadığını kabul edersiniz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Ayrıca, tüm Güncellemelerin (i) web sitesinin ayrılmaz bir parçasını oluşturduğunun kabul edileceğini ve (ii) bu Sözleşmenin hüküm ve koşullarına tabi olacağını kabul edersiniz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Üçüncü Taraf Hizmetleri</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Üçüncü taraf içeriğini (veriler, bilgiler, uygulamalar ve diğer ürün hizmetleri dahil) görüntüleyebilir, dahil edebilir veya kullanıma sunabilir veya üçüncü taraf web sitelerine veya hizmetlerine ("Üçüncü Taraf Hizmetleri") bağlantılar sağlayabiliriz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik'in doğruluğu, eksiksizliği, güncelliği, geçerliliği, telif haklarına uygunluğu, yasallığı, dürüstlüğü, kalitesi veya diğer herhangi bir yönü dahil olmak üzere herhangi bir Üçüncü Taraf Hizmetinden sorumlu olmayacağını kabul ve beyan etmektesiniz. Gelsin Teknik, herhangi bir Üçüncü Taraf Hizmeti için size veya başka herhangi bir kişi veya kuruluşa karşı herhangi bir yükümlülük veya sorumluluk kabul etmez ve olmayacaktır.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Üçüncü Taraf Hizmetleri ve bunlara bağlantılar yalnızca size kolaylık sağlamak amacıyla sağlanır ve bunlara tamamen kendi sorumluluğunuzda ve bu tür üçüncü tarafların hüküm ve koşullarına tabi olarak erişir ve kullanırsınız.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Süre ve Fesih</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                İşbu Sözleşme, siz veya Gelsin Teknik tarafından feshedilene kadar yürürlükte kalacaktır.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, tamamen kendi takdirine bağlı olarak, herhangi bir zamanda ve herhangi bir nedenle veya hiçbir nedenle, önceden bildirimde bulunarak veya bulunmaksızın bu Sözleşmeyi askıya alabilir veya feshedebilir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Bu Sözleşme, bu Sözleşmenin herhangi bir hükmüne uymamanız durumunda Gelsin Teknik'ten önceden haber vermeksizin derhal feshedilecektir. Bu Sözleşmeyi, web sitesini ve tüm kopyalarını bilgisayarınızdan silerek de feshedebilirsiniz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Bu Sözleşmenin feshi üzerine, web sitesinin tüm kullanımını durduracak ve web sitesinin tüm kopyalarını bilgisayarınızdan sileceksiniz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Bu Sözleşmenin feshi, işbu Sözleşme kapsamındaki yükümlülüklerinizden herhangi birinin sizin tarafınızdan (bu Sözleşmenin süresi boyunca) ihlal edilmesi durumunda Gelsin Teknik'in kanun veya hakkaniyet çerçevesindeki haklarını veya çözüm yollarını sınırlamayacaktır.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Telif Hakkı İhlali Bildirimi</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bir telif hakkı sahibi veya bu tür bir sahibinin ™temsilcisiyseniz ve web sitemizdeki herhangi bir materyalin telif hakkınızı ihlal ettiğine inanıyorsanız, lütfen aşağıdaki bilgileri vererek bizimle iletişime geçin: (a) telif hakkı sahibinin veya onun adına hareket etmeye yetkili bir kişinin fiziksel veya elektronik imzası; (b) ihlal ettiği iddia edilen materyalin tanımlanması; (c) adresiniz, telefon numaranız ve e-posta adresiniz dahil olmak üzere iletişim bilgileriniz; (d) materyalin kullanımının telif hakkı sahipleri tarafından yetkilendirilmediğine iyi niyetle inandığınıza dair bir beyanınız; ve (e) bildirimdeki bilgilerin doğru olduğuna ve yalan beyanda bulunma cezası altında mal sahibi adına hareket etmeye yetkili olduğunuza dair bir beyan.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Tazminat</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik'i ve ana şirketlerini, iştiraklerini, iştiraklerini, memurlarını, çalışanlarını, acentelerini, ortaklarını ve lisans verenlerini (varsa) makul avukatlık ücretleri de dahil olmak üzere aşağıdakilerden kaynaklanan veya bunlardan kaynaklanan herhangi bir iddia veya talebe karşı tazmin etmeyi ve zarar görmemesini sağlamayı kabul edersiniz: (a) web sitesinin kullanımı; (b) bu Sözleşmenin veya herhangi bir yasa veya yönetmeliğin ihlali; veya (c) üçüncü bir tarafın herhangi bir hakkının ihlali.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Garanti Verilmemesi</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Web sitesi size "OLDUĞU GİBİ" ve "MEVCUT OLDUĞU GİBİ" ve herhangi bir garanti olmaksızın tüm hata ve kusurlarla birlikte sunulmaktadır. Yürürlükteki yasaların izin verdiği azami ölçüde, Gelsin Teknik, kendi adına ve iştirakleri ve bunların ilgili lisans verenleri ve hizmet sağlayıcıları adına, satılabilirlik, belirli bir amaca uygunluk, mülkiyet ve ihlal etmeme gibi tüm zımni garantiler de dahil olmak üzere, web sitesi ile ilgili olarak açık, zımni, yasal veya başka türlü tüm garantileri açıkça reddeder.  ve işlem sırasında, performans, kullanım veya ticari uygulamadan kaynaklanabilecek garantiler. Yukarıda belirtilenlerle sınırlı olmaksızın, Gelsin Teknik, web sitesinin gereksinimlerinizi karşılayacağına, amaçlanan sonuçlara ulaşacağına, uyumlu olacağına veya başka herhangi bir yazılım, web sitesi, sistem veya hizmetle uyumlu olacağına veya çalışacağına, kesintisiz çalışacağına, herhangi bir performans veya güvenilirlik standardını karşılayacağına veya hatasız olacağına veya herhangi bir hata veya kusurun düzeltilebileceğine veya düzeltileceğine dair hiçbir garanti veya taahhütte bulunmaz ve hiçbir beyanda bulunmaz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Yukarıdakileri sınırlamaksızın, ne Gelsin Teknik ne de Gelsin Teknik'in herhangi bir sağlayıcısı, açık veya zımni herhangi bir beyanda bulunmaz veya garanti vermez: (i) web sitesinin çalışması veya kullanılabilirliği veya burada yer alan bilgiler, içerik ve materyaller veya ürünler hakkında; (ii) web sitesinin kesintisiz veya hatasız olacağını; (iii) web sitesi aracılığıyla sağlanan herhangi bir bilgi veya içeriğin doğruluğu, güvenilirliği veya güncelliği ile ilgili olarak; veya (iv) web sitesinin, sunucularının, içeriğinin veya Gelsin Teknik'ten veya Gelsin Teknik adına gönderilen e-postaların virüs, komut dosyası, truva atı, solucan, kötü amaçlı yazılım, saatli bomba veya diğer zararlı bileşenler içermediğini.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Bazı yargı bölgeleri, zımni garantilerin hariç tutulmasına veya sınırlandırılmasına veya bir tüketicinin geçerli yasal haklarına ilişkin sınırlamalara izin vermez, bu nedenle yukarıdaki istisnaların ve sınırlamaların bazıları veya tümü sizin için geçerli olmayabilir.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Sorumluluğun Sınırlandırılması</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Maruz kalabileceğiniz herhangi bir zarara bakılmaksızın, Gelsin Teknik'in ve tedarikçilerinden herhangi birinin bu Sözleşmenin herhangi bir hükmü kapsamındaki tüm sorumluluğu ve yukarıdakilerin tümü için münhasır çözüm yolunuz, web sitesi için fiilen ödediğiniz miktarla sınırlı olacaktır.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Yürürlükteki yasaların izin verdiği azami ölçüde, Gelsin Teknik veya tedarikçileri hiçbir durumda herhangi bir özel, arızi, dolaylı veya sonuç olarak ortaya çıkan zarardan sorumlu tutulamaz (kar kaybı, veri veya diğer bilgilerin kaybı, iş kesintisi, kişisel yaralanma, web sitesinin kullanımından veya kullanılamamasından kaynaklanan veya bunlarla herhangi bir şekilde ilgili olan gizlilik kaybı dahil ancak bunlarla sınırlı olmamak üzere,  web sitesi ile birlikte veya başka bir şekilde bu Sözleşmenin herhangi bir hükmü ile bağlantılı olarak kullanılan üçüncü taraf yazılımı ve/veya üçüncü taraf donanımı), Gelsin Teknik veya herhangi bir tedarikçi bu tür zararların olasılığı konusunda bilgilendirilmiş olsa ve çözüm temel amacını yerine getiremese bile.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Bazı eyaletler/yargı bölgeleri, arızi veya sonuç olarak ortaya çıkan zararların hariç tutulmasına veya sınırlandırılmasına izin vermemektedir, bu nedenle yukarıdaki sınırlama veya hariç tutma sizin için geçerli olmayabilir.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Bölünebilirlik</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bu Sözleşmenin herhangi bir hükmünün uygulanamaz veya geçersiz olduğuna karar verilirse, söz konusu hüküm, yürürlükteki yasalar kapsamında mümkün olan en geniş ölçüde söz konusu hükmün amaçlarını gerçekleştirmek için değiştirilecek ve yorumlanacak ve geri kalan hükümler tam olarak yürürlükte kalmaya devam edecektir.  
            </p>
            <p>
                &emsp;&emsp;&emsp;
                İşbu Sözleşme, Gizlilik Politikası ve Gelsin Teknik tarafından Hizmetler'e ilişkin olarak yayınlanan diğer yasal bildirimlerle birlikte, Hizmetler'e ilişkin olarak siz ve Gelsin Teknik arasındaki sözleşmenin tamamını oluşturacaktır. Bu Sözleşmenin herhangi bir hükmünün yetkili bir mahkeme tarafından geçersiz sayılması durumunda, söz konusu hükmün geçersizliği, bu Sözleşmenin tam olarak yürürlükte kalacak olan geri kalan hükümlerinin geçerliliğini etkilemeyecektir. Bu Sözleşmenin herhangi bir şartından feragat edilmesi, söz konusu şarttan veya başka herhangi bir şarttan daha fazla veya devam eden bir feragat olarak kabul edilmeyecektir ve Gelsin Teknik'™in bu Sözleşme kapsamında herhangi bir hak veya hüküm ileri sürmemesi, söz konusu hak veya hükümden feragat ettiği anlamına gelmez. SİZ VE GELİN TEKNİK, HİZMETLERDEN KAYNAKLANAN VEYA HİZMETLERLE İLGİLİ HERHANGİ BİR DAVA SEBEBİNİN, DAVA HAKKININ TAHAKKUK ETMESİNDEN İTİBAREN BİR (1) YIL İÇİNDE BAŞLAMASI GEREKTİĞİNİ KABUL EDERSİNİZ. AKSI TAKDIRDE, BU TÜR BIR DAVA NEDENI KALICI OLARAK ENGELLENIR.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Feragat</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Burada belirtilenler dışında, bu Sözleşme kapsamındaki bir hakkın kullanılmaması veya bir yükümlülüğün yerine getirilmesinin talep edilmemesi, bir tarafın bu hakkı kullanma kabiliyetini etkilemeyecek veya daha sonra herhangi bir zamanda bu tür bir ifayı gerektirmeyecek veya bir ihlalden feragat edilmesi, sonraki herhangi bir ihlalden feragat edildiği anlamına gelmeyecektir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Taraflardan herhangi birinin bu Sözleşme kapsamındaki herhangi bir hakkı veya yetkiyi kullanmaması veya kullanmakta gecikmesi, bu hak veya yetkiden feragat edildiği anlamına gelmez. Bu Sözleşme kapsamındaki herhangi bir hak veya yetkinin tek başına veya kısmen kullanılması, söz konusu hakkın veya burada verilen başka herhangi bir hakkın daha fazla kullanılmasını da engellemeyecektir. Bu Sözleşme ile geçerli herhangi bir satın alma veya diğer şartlar arasında bir çelişki olması durumunda, bu Sözleşmenin şartları geçerli olacaktır.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Bu Sözleşmedeki Değişiklikler</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, tamamen kendi takdirine bağlı olarak, bu Sözleşmeyi herhangi bir zamanda değiştirme veya değiştirme hakkını saklı tutar. Bir revizyon önemliyse, yeni şartların yürürlüğe girmesinden en az 30 gün önce bildirimde bulunacağız. Önemli bir değişikliği neyin oluşturduğu tamamen kendi takdirimize bağlı olarak belirlenecektir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Herhangi bir revizyon yürürlüğe girdikten sonra web sitemize erişmeye veya kullanmaya devam ederek, revize edilmiş şartlara bağlı kalmayı kabul etmiş olursunuz. Yeni şartları kabul etmiyorsanız, artık Gelsin Teknik'i kullanma yetkiniz yoktur.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Sözleşmenin Bütünlüğü</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Sözleşme, web sitesini kullanımınızla ilgili olarak siz ve Gelsin Teknik arasındaki sözleşmenin tamamını oluşturur ve siz ve Gelsin Teknik arasındaki önceki ve eş zamanlı tüm yazılı veya sözlü sözleşmelerin yerine geçer. 
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik'in diğer hizmetlerini kullandığınızda veya satın aldığınızda geçerli olan ve Gelsin Teknik'in bu tür bir kullanım veya satın alma sırasında size sağlayacağı ek hüküm ve koşullara tabi olabilirsiniz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Koşullarımızdaki Güncellemeler</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Hizmetimizi ve politikalarımızı değiştirebiliriz ve Hizmetimizi ve politikalarımızı doğru bir şekilde yansıtmaları için bu Koşullarda değişiklik yapmamız gerekebilir. Yasalar aksini gerektirmedikçe, bu Koşullarda değişiklik yapmadan önce sizi bilgilendireceğiz (örneğin, Hizmetimiz aracılığıyla) ve yürürlüğe girmeden önce bunları gözden geçirme fırsatı vereceğiz. Ardından, Hizmeti kullanmaya devam ederseniz, güncellenen Şartlara bağlı kalacaksınız. Bunları veya herhangi bir güncellenmiş Şartı kabul etmek istemiyorsanız, hesabınızı silebilirsiniz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Fikri mülkiyet</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Web sitesi ve tüm içeriği, özellikleri ve işlevselliği (tüm bilgiler, yazılımlar, metinler, görüntüler, resimler, video ve ses ve bunların tasarımı, seçimi ve düzenlenmesi dahil ancak bunlarla sınırlı olmamak üzere) Gelsin Teknik'e, lisans verenlerine veya bu tür materyallerin diğer sağlayıcılarına aittir ve Türkiye ve uluslararası telif hakkı, ticari marka, patent, ticari sır ve diğer fikri mülkiyet veya mülkiyet hakları yasaları tarafından korunmaktadır. Materyal, bu Şartlar ve Koşullarda açıkça belirtilmedikçe, Gelsin Teknik'in önceden açık yazılı izni olmaksızın tamamen veya kısmen hiçbir şekilde kopyalanamaz, değiştirilemez, çoğaltılamaz, indirilemez veya dağıtılamaz. Materyalin izinsiz kullanımı yasaktır.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Tahkim Anlaşması</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bu bölüm, SİZİN VEYA Gelsin Teknik'™İN FIKRI MÜLKIYET HAKLARININ UYGULANMASI VEYA GEÇERLİLİĞİ İLE İLGİLİ İHTİYATİ TEDBİR VEYA ADİL TEDBİR TALEPLERİYLE İLGİLİ BİR ANLAŞMAZLIĞI İÇERMEDİĞİ HARİÇ, herhangi bir anlaşmazlık için ™geçerlidir. "Anlaşmazlık" terimi, sizinle Gelsin Teknik arasında Hizmetler veya bu sözleşme ile ilgili olarak, sözleşme, garanti, haksız fiil, tüzük, yönetmelik, yönetmelik veya diğer herhangi bir yasal veya hakkaniyet temelinde olsun, herhangi bir anlaşmazlık, eylem veya diğer ihtilaflar anlamına gelir. "Anlaşmazlık", yasaların izin verdiği mümkün olan en geniş anlam verilecektir.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Anlaşmazlık Bildirimi</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bir anlaşmazlık durumunda, siz veya Gelsin Teknik, diğer tarafa, onu veren tarafın adını, adresini ve iletişim bilgilerini, anlaşmazlığa neden olan gerçekleri ve talep edilen çözümü belirten yazılı bir beyan olan bir Anlaşmazlık Bildirimi vermelisiniz. Herhangi bir Anlaşmazlık Bildirimini e-posta yoluyla şu adrese göndermelisiniz: Â info@gelsinteknik.com. Gelsin Teknik, herhangi bir Anlaşmazlık Bildirimini, varsa adresinize posta yoluyla, aksi takdirde e-posta adresinize gönderecektir. Siz ve Gelsin Teknik, herhangi bir anlaşmazlığı, Anlaşmazlık Bildiriminin gönderildiği tarihten itibaren altmış (60) gün içinde gayri resmi müzakere yoluyla çözmeye çalışacaksınız. Altmış (60) gün sonra, siz veya Gelsin Teknik tahkime başlayabilirsiniz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Bağlayıcı Tahkim</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Siz ve Gelsin Teknik herhangi bir anlaşmazlığı gayri resmi müzakere yoluyla çözemezseniz ™, anlaşmazlığı çözmeye yönelik diğer tüm çabalar münhasıran bu bölümde açıklandığı gibi bağlayıcı tahkim yoluyla yürütülecektir. Mahkemedeki tüm anlaşmazlıkları bir yargıç veya jüri önünde dava etme (veya taraf veya sınıf üyesi olarak katılma) hakkından vazgeçiyorsunuz. Anlaşmazlık, Amerikan Tahkim Derneği'nin ticari tahkim kurallarına uygun olarak bağlayıcı tahkim yoluyla çözülecektir. Taraflardan herhangi biri ™, tahkimin tamamlanmasına kadar tarafın haklarını veya mülkiyetini korumak için gerekli olduğu şekilde, herhangi bir yetkili mahkemeden herhangi bir geçici veya ön ihtiyati tedbir talep edebilir. Kazanan tarafın maruz kaldığı her türlü yasal, muhasebe ve diğer masraflar, ücretler ve harcamalar, hakim olmayan tarafça karşılanacaktır.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Gönderimler ve Gizlilik</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Yeni veya geliştirilmiş ürünler, hizmetler, özellikler, teknolojiler veya promosyonlar için fikirler de dahil olmak üzere herhangi bir fikir, yaratıcı öneri, tasarım, fotoğraf, bilgi, reklam, veri veya teklif göndermeniz veya yayınlamanız durumunda, bu tür gönderilerin otomatik olarak gizli ve tescilli olmayan olarak değerlendirileceğini ve size herhangi bir tazminat veya kredi olmaksızın Gelsin Teknik'in münhasır mülkü olacağını açıkça kabul edersiniz. Gelsin Teknik ve iştiraklerinin bu tür gönderiler veya gönderilerle ilgili hiçbir yükümlülüğü yoktur ve bu tür gönderilerde veya gönderilerde yer alan fikirleri, bu tür fikirleri kullanarak ürün ve hizmetlerin geliştirilmesi, üretilmesi ve pazarlanması dahil ancak bunlarla sınırlı olmamak üzere, herhangi bir ortamda herhangi bir amaçla kalıcı olarak kullanabilir.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Promosyon</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, zaman zaman kendinizle ilgili materyal veya bilgi göndermenizi gerektiren yarışmalar, promosyonlar, çekilişler veya diğer faaliyetleri ("Promosyonlar") içerebilir. Tüm Promosyonların, yaş ve coğrafi konum kısıtlamaları gibi belirli uygunluk gerekliliklerini içerebilecek ayrı kurallara tabi olabileceğini lütfen unutmayın. Katılmaya uygun olup olmadığınızı belirlemek için tüm Promosyon kurallarını okumak sizin sorumluluğunuzdadır. Herhangi bir Promosyona girerseniz, tüm Promosyon Kurallarına uymayı ve bunlara uymayı kabul edersiniz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Hizmetler üzerinden veya Hizmetler aracılığıyla mal veya hizmet satın alımları için ek hüküm ve koşullar geçerli olabilir ve bu hüküm ve koşullar bu referansla bu Sözleşmenin bir parçası haline getirilmiştir.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Tipografik Hatalar</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bir ürün ve/veya hizmetin yanlış bir fiyatla veya yazım hatası nedeniyle yanlış bilgilerle listelenmesi durumunda, yanlış fiyattan listelenen ürün ve/veya hizmet için verilen siparişleri reddetme veya iptal etme hakkımız olacaktır. Siparişin onaylanıp onaylanmadığına ve kredi kartınızdan tahsilat yapılıp yapılmadığına bakılmaksızın bu tür bir siparişi reddetme veya iptal etme hakkına sahip olacağız. Satın alma işlemi için kredi kartınızdan zaten ödeme alınmışsa ve siparişiniz iptal edilmişse, kredi kartı hesabınıza veya başka bir ödeme hesabınıza derhal ücret tutarında bir kredi vereceğiz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Çeşitli</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Herhangi bir nedenle yetkili bir mahkeme bu Hüküm ve Koşulların herhangi bir hükmünün veya bölümünün uygulanamaz olduğunu tespit ederse, bu Hüküm ve Koşulların geri kalanı tam olarak yürürlükte kalmaya devam edecektir. Bu Şartlar ve Koşulların herhangi bir hükmünden feragat, yalnızca yazılı olarak ve Gelsin Teknik'in yetkili bir temsilcisi tarafından imzalanmışsa geçerli olacaktır. Gelsin Teknik, tarafınızdan herhangi bir ihlal veya öngörülen ihlal durumunda ihtiyati tedbir veya diğer parasal olmayan tedbirlere (herhangi bir kefalet veya kefalet gönderme yükümlülüğü olmaksızın) hak kazanacaktır. Gelsin Teknik, Gelsin Teknik Servis'i 1/4rkiye'deki ofislerinden işletmekte ve kontrol etmektedir. Hizmet, bu tür bir dağıtım veya kullanımın yasalara veya düzenlemelere aykırı olacağı herhangi bir yargı yetkisi veya ülkedeki herhangi bir kişi veya kuruluşa dağıtılmak veya bunlar tarafından kullanılmak üzere tasarlanmamıştır. Buna göre, Gelsin Teknik Servisine başka yerlerden erişmeyi seçen kişiler, bunu kendi inisiyatifleriyle yaparlar ve yerel yasaların geçerli olması durumunda ve geçerli olduğu ölçüde, yerel yasalara uymaktan yalnızca kendileri sorumludur. Bu Şartlar ve Koşullar (Gelsin Teknik Gizlilik Politikası'nı içerir ve dahil eder) tüm anlaşmayı içerir ve konusuyla ilgili olarak siz ve Gelsin Teknik arasındaki önceki tüm anlaşmaların yerine geçer ve sizin tarafınızdan değiştirilemez veya değiştirilemez. Bu Sözleşmede kullanılan bölüm başlıkları yalnızca kolaylık sağlamak içindir ve herhangi bir yasal ithalat yapılmayacaktır.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Feragatname</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik herhangi bir içerik, kod veya diğer herhangi bir belirsizlikten sorumlu değildir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik garanti veya teminat vermemektedir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik hiçbir durumda, Hizmetin veya Hizmetin içeriğinin kullanımından kaynaklanan veya bunlarla bağlantılı olarak ortaya çıkan herhangi bir özel, doğrudan, dolaylı, sonuç olarak ortaya çıkan veya arızi zararlardan veya herhangi bir sözleşme, ihmal veya diğer haksız fiillerden kaynaklanan herhangi bir zarardan sorumlu tutulamaz. Şirket, önceden haber vermeksizin herhangi bir zamanda Hizmetteki içeriğe ekleme, silme veya değişiklik yapma hakkını saklı tutar.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik Hizmeti ve içeriği, açık veya zımni herhangi bir garanti veya beyan olmaksızın "olduğu gibi" ve "mevcut olduğu gibi" sağlanmaktadır. Gelsin Teknik, üçüncü şahıslar tarafından sağlanan içeriğin yayıncısı değil, distribütörüdür; bu nedenle, Gelsin Teknik bu tür içerikler üzerinde hiçbir editoryal kontrol uygulamaz ve Gelsin Teknik Hizmeti aracılığıyla sağlanan veya erişilebilen herhangi bir bilgi, içerik, hizmet veya ticari ürünün doğruluğu, güvenilirliği veya güncelliği konusunda hiçbir garanti veya beyanda bulunmaz. Yukarıda belirtilenleri sınırlamaksızın, Gelsin Teknik, Gelsin Teknik Hizmetinde veya Gelsin Teknik Hizmetinde bağlantı olarak görünebilecek sitelerde veya Gelsin Teknik Hizmetinin bir parçası olarak veya başka bir şekilde bağlantılı olarak sağlanan ürünlerde iletilen herhangi bir içerikteki tüm garantileri ve beyanları özellikle reddeder,  satılabilirlik, belirli bir amaca uygunluk veya üçüncü taraf haklarının ihlal edilmemesi ile ilgili garantiler dahil ancak bunlarla sınırlı olmamak üzere. Gelsin Teknik veya bağlı kuruluşları, çalışanları, memurları, yöneticileri, acenteleri veya benzerleri tarafından verilen hiçbir sözlü tavsiye veya yazılı bilgi bir garanti oluşturmaz. Fiyat ve müsaitlik bilgileri önceden haber verilmeksizin değiştirilebilir. Yukarıda belirtilenleri sınırlamaksızın, Gelsin Teknik, Gelsin Teknik Servisinin kesintisiz, bozulmamış, zamanında veya hatasız olacağını garanti etmez.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Bize Ulaşın</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Herhangi bir sorunuz varsa bizimle iletişime geçmekten çekinmeyin.
            </p>
            <ul>
                <li class="p-m-2">E-posta yoluyla: info@gelsinteknik.com</li>
                <li class="p-m-2">Bu Bağlantı Üzerinden: www.gelsinteknik.com</li>
            </ul>
        </div>
        <div class="p-col-12 p-d-flex p-jc-center">
            <Button label="Okudum, Anladım, Onaylıyorum" severity="danger" outlined @click="OnayButton()" />
        </div>
    </div>

</template>
<script>
export default {
    props:{
        OnayDurum : Boolean
    },
    data() {
        return {}
    },
    methods: {
        OnayButton() {
            this.$emit("get-onay-data", true);
        }
    },
    components: {
    }
}
</script>
<style></style>