import axios from 'axios';

class InfoService {
    InfoService() {
    return axios({
      method: 'GET',
      url: 'https://api.db-ip.com/v2/free/self',
    }).then((response) => {
      console.log(response);
        return response;
    }).catch(function (error) {
        return error;
    })
  }

}

export default new InfoService();

